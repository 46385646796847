import React, { useState } from 'react';
import FolderIcon from '@mui/icons-material/Folder';
import FileIcon from '@mui/icons-material/Description';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import "./App.css";

const FileTree = ({ data, isOpen = false }) => {
  const [open, setOpen] = useState(isOpen);

  const handleToggle = () => {
    setOpen(!open);
  };



  const renderTree = (node, isOpen) => (
    <div
      style={{
        marginLeft: '1rem',
        padding: '0.5rem',
        color: '#fff',
        transition: 'all 0.3s ease',
        borderLeft: node.type === 'folder' ? 'none' : 'none',
        transform: open ? 'translateX(0)' : 'translateX(-20px)',
        opacity: open ? 1 : 0.7,
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        {node.children && node.name !== 'Number_Bot' && (open ? <ExpandMoreIcon onClick={handleToggle} style={{ color: '#FFEB3B' }} /> : <ChevronRightIcon onClick={handleToggle} style={{ color: '#FFEB3B' }} />)}
        {node.type === 'folder' ? (
          <FolderIcon style={{ marginRight: '0.5rem', color: '#3F51B5' }} />
        ) : (
          <FileIcon style={{ marginRight: '0.5rem', color: '#009688' }} />
        )}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>{node.name}</div>
          {node.description && <small style={{ color: '#aaa' }}>{node.description}</small>}
        </div>
      </div>
      {node.children && open && (
        <div
          style={{
            marginLeft: '1rem',
            padding: '0.5rem',
            color: '#fff',
            transition: 'all 0.3s ease',
            borderLeft: '1px solid #ccc',
            transform: open ? 'translateX(0)' : 'translateX(-20px)',
            opacity: open ? 1 : 0.7,
          }}
        >
          {node.children.map((child, index) => (
            <div key={index}>
              <FileTree data={child} isOpen={node.name === 'src' ? false : isOpen} className="file-tree"/>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  return renderTree(data, isOpen);
};

const App = () => {
  const folderStructure = {
    name: '🌸 Amira 🌸',
    type: 'folder',
    description: 'Directorio principal de 🌸 Amira 🌸',
    children: [
      { name: '.env', type: 'file', file: '.env', description: 'Archivo de variables de entorno' },
      { name: 'package.json', type: 'file', file: 'package.json', description: 'Archivo de configuración de paquetes' },
      {
        name: 'src',
        type: 'folder',
        description: 'Directorio que contiene el código fuente del bot',
        children: [
          { name: 'index.js', type: 'file', file: 'index.js', description: 'Archivo principal del código' },
          {
            name: 'comandos',
            type: 'folder',
            description: 'Directorio que contiene los comandos del bot',
            children: [
              {
                name: 'Dueño',
                type: 'folder',
                description: 'Comandos exclusivos para el dueño del bot',
                children: [
                  { name: 'donation.js', type: 'file', file: 'donation.js', description: 'Comando para donaciones' },
                  { name: 'reglas.js', type: 'file', file: 'reglas.js', description: 'Comando para ver las reglas' },
                  { name: 'vote.js', type: 'file', file: 'vote.js', description: 'Comando para votar' },
                ],
              },
            ],
          },
          {
            name: 'eventos',
            type: 'folder',
            description: 'Eventos del bot',
            children: [
              {
                name: 'client',
                type: 'folder',
                description: 'Eventos del cliente del bot',
                children: [
                  { name: 'ready.js', type: 'file', file: 'ready.js', description: 'Evento cuando el cliente está listo' },
                ],
              },
              {
                name: 'guild',
                type: 'folder',
                description: 'Eventos del servidor del bot',
                children: [
                  { name: 'interactionCreate.js', type: 'file', file: 'interactionCreate.js', description: 'Evento cuando se recibe una interacción' },
                  { name: 'messageCreate.js', type: 'file', file: 'messageCreate.js', description: 'Evento cuando se recibe un mensaje' },
                ],
              },
            ],
          },
          {
            name: 'fonts',
            type: 'folder',
            description: 'Fuentes utilizadas en el bot',
            children: [
              { name: 'sofiapro.ttf', type: 'file', file: 'sofiapro.ttf', description: 'Fuente Sofia Pro' },
              { name: 'unisans.ttf', type: 'file', file: 'unisans.ttf', description: 'Fuente Unisans' },
            ],
          },
          {
            name: 'handlers',
            type: 'folder',
            description: 'Manejadores del bot',
            children: [
              { name: 'antiCrash.js', type: 'file', file: 'antiCrash.js', description: 'Manejador anti crash' },
              { name: 'autorol.js', type: 'file', file: 'autorol.js', description: 'Manejador de autorrol' },
              { name: 'ayuda.js', type: 'file', file: 'ayuda.js', description: 'Manejador de ayuda' },
              { name: 'bienvenidas.js', type: 'file', file: 'bienvenidas.js', description: 'Manejador de bienvenidas' },
              { name: 'channelvoice.js', type: 'file', file: 'channelvoice.js', description: 'Manejador de canales de voz' },
              { name: 'conteo.js', type: 'file', file: 'conteo.js', description: 'Manejador de conteo' },
              { name: 'distube.js', type: 'file', file: 'distube.js', description: 'Manejador de Distube' },
              { name: 'messages.js', type: 'file', file: 'messages.js', description: 'Manejador de mensajes' },
              { name: 'morals.js', type: 'file', file: 'morals.js', description: 'Manejador de moralejas' },
              { name: 'niveles.js', type: 'file', file: 'niveles.js', description: 'Manejador de niveles' },
              { name: 'noMentions.js', type: 'file', file: 'noMentions.js', description: 'Manejador de menciones' },
              { name: 'servers.js', type: 'file', file: 'servers.js', description: 'Manejador de servidores' },
              { name: 'sugerencias.js', type: 'file', file: 'sugerencias.js', description: 'Manejador de sugerencias' },
              { name: 'tickets1.js', type: 'file', file: 'tickets1.js', description: 'Manejador de tickets (parte 1)' },
              { name: 'tickets2.js', type: 'file', file: 'tickets2.js', description: 'Manejador de tickets (parte 2)' },
            ],
          },
          {
            name: 'modelos',
            type: 'folder',
            description: 'Modelos Base de Datos del bot',
            children: [
              { name: 'autorol.js', type: 'file', file: 'autorol.js', description: 'Modelo de autorrol' },
              { name: 'canaldevoz.js', type: 'file', file: 'canaldevoz.js', description: 'Modelo de canal de voz' },
              { name: 'conteo.js', type: 'file', file: 'conteo.js', description: 'Modelo de conteo' },
              { name: 'economia.js', type: 'file', file: 'economia.js', description: 'Modelo de economía' },
              { name: 'morals.js', type: 'file', file: 'morals.js', description: 'Modelo de moralejas' },
              { name: 'servidor.js', type: 'file', file: 'servidor.js', description: 'Modelo de servidor' },
              { name: 'setups.js', type: 'file', file: 'setups.js', description: 'Modelo de setups' },
              { name: 'tickets.js', type: 'file', file: 'tickets.js', description: 'Modelo de tickets' },
              { name: 'votos-sugs.js', type: 'file', file: 'votos-sugs.js', description: 'Modelo de votos y sugerencias' },
              { name: 'warns.js', type: 'file', file: 'warns.js', description: 'Modelo de advertencias' },
            ],
          },
          {
            name: 'slashCommands',
            type: 'folder',
            description: 'Comandos slash del bot',
            children: [
              {
                name: 'Admin',
                type: 'folder',
                description: 'Comandos de administración',
                children: [
                  { name: 'ban.js', type: 'file', file: 'ban.js', description: 'Comando para banear a un usuario' },
                  { name: 'clear.js', type: 'file', file: 'clear.js', description: 'Comando para limpiar mensajes' },
                  { name: 'kick.js', type: 'file', file: 'kick.js', description: 'Comando para expulsar a un usuario' },
                  { name: 'nuke.js', type: 'file', file: 'nuke.js', description: 'Comando para "nukear" un canal' },
                  { name: 'unban.js', type: 'file', file: 'unban.js', description: 'Comando para desbanear a un usuario' },
                ],
              },
              {
                name: 'Dueño',
                type: 'folder',
                description: 'Comandos exclusivos para el dueño del bot',
                children: [
                  { name: 'anuncio.js', type: 'file', file: 'anuncio.js', description: 'Comando para realizar anuncios' },
                  { name: 'compras.js', type: 'file', file: 'compras.js', description: 'Comando para ver compras' },
                  { name: 'poweroff.js', type: 'file', file: 'poweroff.js', description: 'Comando para apagar el bot' },
                  { name: 'reload.js', type: 'file', file: 'reload.js', description: 'Comando para recargar el bot' },
                  { name: 'wallet.js', type: 'file', file: 'wallet.js', description: 'Comando para ver la billetera' },
                ],
              },
              {
                name: 'Info',
                type: 'folder',
                description: 'Comandos de información',
                children: [
                  { name: 'avatar.js', type: 'file', file: 'avatar.js', description: 'Comando para ver el avatar de un usuario' },
                  { name: 'botinfo.js', type: 'file', file: 'botinfo.js', description: 'Comando para ver información del bot' },
                  { name: 'cualesmiip.js', type: 'file', file: 'cualesmiip.js', description: 'Comando para obtener la IP del bot' },
                  { name: 'invitebot.js', type: 'file', file: 'invitebot.js', description: 'Comando para obtener enlace de invitación del bot' },
                  { name: 'level.js', type: 'file', file: 'level.js', description: 'Comando para ver el nivel de un usuario' },
                  { name: 'ping.js', type: 'file', file: 'ping.js', description: 'Comando para verificar la latencia del bot' },
                  { name: 'reportbug.js', type: 'file', file: 'reportbug.js', description: 'Comando para reportar errores' },
                  { name: 'userinfo.js', type: 'file', file: 'userinfo.js', description: 'Comando para ver información de un usuario' },
                ],
              },
              {
                name: 'Interactivos',
                type: 'folder',
                description: 'Comandos interactivos',
                children: [
                  { name: '8ball.js', type: 'file', file: '8ball.js', description: 'Comando de la bola mágica' },
                  { name: 'dice.js', type: 'file', file: 'dice.js', description: 'Comando para lanzar un dado' },
                  { name: 'gei.js', type: 'file', file: 'gei.js', description: 'Comando para enviar un mensaje "gei"' },
                  { name: 'hug.js', type: 'file', file: 'hug.js', description: 'Comando para enviar un abrazo' },
                  { name: 'invites.js', type: 'file', file: 'invites.js', description: 'Comando para ver invitaciones' },
                  { name: 'joke.js', type: 'file', file: 'joke.js', description: 'Comando para obtener un chiste' },
                  { name: 'kiss.js', type: 'file', file: 'kiss.js', description: 'Comando para enviar un beso' },
                  { name: 'meme.js', type: 'file', file: 'meme.js', description: 'Comando para obtener un meme' },
                  { name: 'shot.js', type: 'file', file: 'shot.js', description: 'Comando para tomar un chupito' },
                ],
              },
              {
                name: 'Musica',
                type: 'folder',
                description: 'Comandos de música del bot',
                children: [
                  { name: 'play.js', type: 'file', file: 'play.js', description: 'Comando para reproducir música' },
                  { name: 'queue.js', type: 'file', file: 'queue.js', description: 'Comando para ver la lista de reproducción' },
                ],
              },
              {
                name: 'Setup',
                type: 'folder',
                description: 'Comandos de configuración',
                children: [
                  { name: 'setup-autorol.js', type: 'file', file: 'setup-autorol.js', description: 'Comando para configurar autorrol' },
                  { name: 'setup-bienvenida.js', type: 'file', file: 'setup-bienvenida.js', description: 'Comando para configurar mensaje de bienvenida' },
                  { name: 'setup-channelvoice.js', type: 'file', file: 'setup-channelvoice.js', description: 'Comando para configurar canales de voz' },
                  { name: 'setup-counting.js', type: 'file', file: 'setup-counting.js', description: 'Comando para configurar conteo' },
                  { name: 'setup-levels.js', type: 'file', file: 'setup-levels.js', description: 'Comando para configurar niveles' },
                  { name: 'setup-morals.js', type: 'file', file: 'setup-morals.js', description: 'Comando para configurar moralejas' },
                  { name: 'setup-prefix.js', type: 'file', file: 'setup-prefix.js', description: 'Comando para cambiar el prefijo' },
                  { name: 'setup-suggestions.js', type: 'file', file: 'setup-suggestions.js', description: 'Comando para configurar sugerencias' },
                  { name: 'setup-tickets.js', type: 'file', file: 'setup-tickets.js', description: 'Comando para configurar tickets' },
                ],
              },
            ],
          },
          {
            name: 'structures',
            type: 'folder',
            description: 'Estructura del bot',
            children: [
              { name: 'Client.js', type: 'file', file: 'Client.js', description: 'Implementa funcionalidades y manejo de eventos' },
              { name: 'Utils.js', type: 'file', file: 'Utils.js', description: 'Utilidades comunes, mejoran la modularidad del código' },
            ],
          },
          {
            name: 'utils',
            type: 'folder',
            description: 'Funciones del bot',
            children: [
              { name: 'funciones.js', type: 'file', file: 'funciones.js', description: 'Almacena funciones específicas y utilidades' },
            ],
          },
        ],
      }
    ]
  };


    return(
    <>
      <CssBaseline />
      <Container
        maxWidth="md"
        style={{
          display: 'flex',
          flexDirection: 'row',
          padding: '2rem',
          background: '#333',
          borderRadius: '8px',
        }}
      >
        <div style={{ flex: 1 }}>
          <h1 style={{ textAlign: 'center', marginBottom: '2rem', color: '#fff' }}>Listado de rutas de carpetas de 🌸 Amira 🌸</h1>
          <FileTree data={folderStructure} isOpen={true} style={{ cursor: 'url("/pointer.png"), auto' }}/>
        </div>
        <div style={{ width: '150px', height: '150px', overflow: 'hidden', borderRadius: '50%', marginLeft: '1rem' }}>
          <img
            src="https://i.imgur.com/SA71ebU.png"
            alt="Avatar"
            style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%' }}
          />
        </div>
      </Container>
    </>
  );
};

export default App;
